.up-arrow{
    z-index: 5;
    font-size: 35px;
    position: fixed;
    bottom: 4rem;
    right: 2rem;
    color: rgb(190,89,90);
    cursor: pointer;
        
}

.top-btn-hide{
    display: none;
    transition: all 1s;
}