.history-image img{
    max-width: 100%;
}

.history-content p{
    font-size: 16px;
    line-height: 1.5;
    text-align: justify;

}

.history-image img{
    border: 5px solid white;
    box-shadow: 0 0 8px grey;
}

.history-content li{
    list-style: none;
}

.history-icons{
    color: rgb(190,89,90);
}

@media screen and (max-width: 768px) {
    .history-image{
        max-width: 50%;
        margin: auto;
    }

}