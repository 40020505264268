.title{
    border-bottom: 1px solid brown;
}

.display-6{
    text-underline-offset: 16px;
}

.subtitle{
    color: brown;
    letter-spacing: 0.5px;
}

.gallery{
    overflow: hidden;
    transition: 0.5s ease;
}

.gallery-images{
    transition: 0.5s ease;
    width: 426px;
    height: 284px;
}

.gallery-images:hover{
    -webkit-transform: scale(1.08);
    -ms-transform: scale(1.08);
    transform: scale(1.08);
    transition: 0.5s ease;
    
}
