.contact-icons{
    color:rgb(190,89,90) ;

}

.contact-links{
    text-decoration: none;
    color: black;
}

.contact-links:hover{
    color: rgb(190,89,90);
}

.map-column{
    height: 40vh;
}
