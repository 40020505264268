.nav-logo{
    max-width: 120px;
}

.navbar {
    letter-spacing: 0.2px;
}

.navbar-toggler {
    color: transparent;
    border-color: transparent;
}

.navbar-toggler-icon{
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28190, 89, 90, 1%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}


@media screen and (min-width: 992px) {
    .navbar .active {
        color: rgb(190,89,90) !important;
        text-decoration: underline;
        text-underline-offset: 14px;
        text-decoration-thickness: 2px;
    }

    .nav-link:hover {
        color: rgb(190,89,90) !important;
        text-decoration: underline;
        text-underline-offset: 14px;
        text-decoration-thickness: 2px;
        
    }

}

@media screen and (max-width: 992px) {
    .navbar .active {
        color: rgb(190, 89, 90) !important;
    }

    .nav-link:hover {
        color: rgb(190,89,90) !important;
        text-decoration: underline;
        text-underline-offset: 5px;
        text-decoration-thickness: 2px;
        
        
    }

}