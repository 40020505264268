.header{
    background-color: rgb(190,89,90);
    color: white;
    }


.header-links{
    font-size: 14px;
    text-decoration: none;
    color: white;
}

.social-icons{
    color: white;
}

@media screen and (max-width: 992px) {
    .header{
        display: none;
    }
 }



