.about-content span{
color: rgb(190,89,90);
}


.about-content p{
    font-size: 16px;
    color: grey;
    line-height: 1.5;
    text-align: justify; 
}

.about-image-1{
    position: relative;
}

.about-image-1 img{
    border: 5px solid white;
    position : absolute;
    right: -35px;
    top: 35px;
    box-shadow: 0 0 8px grey;
    max-width: 100%;
}

.about-image-2 img{
    border: 5px solid white;
    box-shadow: 0 0 8px grey;
    max-width: 100%;
}

.more-btn{
    padding: 10px;
    box-shadow: 0 0 3px grey;
    transition: all 0.5s ease;
    background-color: rgb(190,89,90);
    color: white;
    border: 1px solid black;
}


.more-btn:hover{
    background-color: white;
    color: black;
    border: 1px solid black;
}

.card{
    border: none;
    box-shadow: 0 0 15px darkgray;
}

.card:hover{
    background-color: rgb(190,89,90);
    color: white;
}

.card-body p{
    text-decoration: none;
}

.sight img {
    transition: 0.5s ease;
    min-width: 100%;
}

.sight {
    position: relative;
    overflow: hidden;
    transition: 0.5s ease;
    box-shadow: 0 0 20px grey;
    
}

.sight-detail-bottom {
    position: absolute;
    bottom: 0;
    top: auto;
    width: 100%;
    height: 15%;
    background: #000;
    opacity: .65;
   
}

.sight-info{
    color: white;
    
}

.sight:hover img {
    -webkit-transform: scale(1.08);
    -ms-transform: scale(1.08);
    transform: scale(1.08);
    transition: 0.5s ease;
    opacity: 100%;
}

.card{
    overflow: hidden;
}
.card-img-top{
    transition: 0.5s ease;
}

.card:hover img{
    -webkit-transform: scale(1.08);
    -ms-transform: scale(1.08);
    transform: scale(1.08);
    transition: 0.5s ease;
}



@media screen and (max-width: 768px) {
    .sight-col{
        max-width: 75%;
        margin-left: auto;
        margin-right: auto;
    }

    .sight img{
        max-width: 100%;
    }

}


@media screen and (max-width: 576px) {
    .more-btn{
        font-size: 15px;
    }
}


.services-block{
    border: 1px solid #f1f1f1;
    box-shadow: 0 0 10px darkgray;
    transition: 0.5s ease;
    border-radius: 4%;
}

.services-block i{
    color: rgb(190,89,90);
}

.services-block:hover{
    transform: translate(0, -10px);
}

@media screen and (max-width: 576px) {
    .services-block{
        width: 75%;
        margin: auto;
        
    }
}