.App{
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  transition: opacity 0.50s, visibility 0.50s;
}

* {
  font-family: 'Roboto';
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@media screen and (max-width: 576px) {
  .container{
      padding-left: 2rem;
      padding-right: 2rem;
  }
}

/* .spinner{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.50s, visibility 0.50s;
} */

/* .spinner-grow{
  width: 50px;
  height: 50px;
  color: rgb(190,89,90);
} */